<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-10-16 11:35:22
 * @LastEditTime: 2019-11-14 17:34:16
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="login-page">
    <b-container>
      <h5 class="logo">
        <i class="fa fa-circle text-gray" />
        ChefStation Supplier
        <i class="fa fa-circle text-warning" />
      </h5>
      <el-card style="width:50%;margin:0 auto;padding-top:30px;">
        <form class="mt" @submit.prevent="login">
          <b-alert class="alert-sm" variant="danger" :show="!!errorMessage">{{errorMessage}}</b-alert>
          <div class="form-group">
            <input
              class="form-control no-border"
              ref="username"
              required
              type="text"
              v-model="username"
              name="username"
              placeholder="Username"
            />
          </div>
          <div class="form-group">
            <input
              class="form-control no-border"
              ref="password"
              required
              type="password"
              v-model="password"
              name="password"
              placeholder="Password"
            />
          </div>
          <div class="clearfix">
            <div class="btn-toolbar float-right">
              <b-button type="submit" size="sm" variant="inverse">Login</b-button>
            </div>
          </div>
        </form>
      </el-card>
    </b-container>
    <footer class="footer">{{$t('aboutus.5A698D@rights_reserved')}}</footer>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  name: "LoginPage",
  data() {
    return {
      errorMessage: null,
      username: "",
      password: ""
    };
  },
  methods: {
    ...mapMutations("websocket", [
      "updataChatMsg",
      "updataChatImg",
      "updataChatList",
      "updataChatInform",
      "updataReqSample",
      "updataReqPrice"
    ]),
    onMessage(msg) {
      let token = this.$getlocalStorage("suppliertoken");
      let lang = this.$getlocalStorage("lang") || "hk";
      const data = JSON.parse(msg.data).data;
      if (JSON.parse(msg.data).errno === 1107) {
        console.log("长连接长时间未操作重新登录", data);
        this.$remlocalStorage("suppliertoken");
        this.$message.warning({
          message:
            this.$getlocalStorage("lang") == "en"
              ? "Long time no operation, please login again!"
              : this.$getlocalStorage("lang") == "cn"
              ? "长时间未操作，请重新登录！"
              : "長時間未操作，請重新登錄！"
        });
        this.$router.push("/login");
        return;
      } else if (JSON.parse(msg.data).errno === 2122) {
        localStorage.clear();
        this.$router.push("/login");
        this.$message.warning(
          `${JSON.parse(msg.data).msg}[errno: ${JSON.parse(msg.data).errno}]`
        );
        return;
      }
      if (data.push_type == "ping") {
        // console.log(data);
        if (this.global.websocket && this.global.websocket.readyState == 1) {
          let params = {
            push_type: "pong",
            room_id: "",
            content: "",
            code: ""
          };
          this.global.websocketsend(params);
          console.log("心跳包");
        }
      } else if (data.push_type == "chat_msg") {
        // console.log("消息", data);
        this.updataChatMsg(data);
      } else if (data.push_type == "chat_img") {
        // console.log("图片", data);
        this.updataChatImg(data);
      } else if (data.push_type == "chat_list") {
        // console.log("列表", data);
        this.updataChatList(data);
      } else if (data.push_type == "chat_inform") {
        // console.log("通知", data);
        this.updataChatInform(data);
      } else if (data.push_type == "req_sample") {
        // console.log("样本", data);
        this.updataReqSample(data);
      } else if (data.push_type == "req_price") {
        // console.log("报价", data);
        this.updataReqPrice(data);
      } else if (data.push_type == "refresh_token") {
        // console.log("刷新token", data);
        this.$setlocalStorage("suppliertoken", data.push_data);
        // this.global.FirstConnection();
      }
    },
    login() {
      if (this.username && this.password) {
        this.$http
          .login({
            user_name: this.username,
            password: this.password
          })
          .then(res => {
            // 存储token/route_id/sub_menu_id
            if (res.errno == 0) {
              let userInfo = {
                avatar: res.data.avatar,
                user_name: res.data.user_name,
                nick_name: res.data.nick_name,
                is_superuser: res.data.is_superuser,
                shop_id: res.data.shop_id
              };
              console.log(this.$setlocalStorage);

              this.$setlocalStorage("suppliertoken", res.data.token);
              this.$setlocalStorage("supplierroute_id", res.data.route_id_json);
              this.$setlocalStorage("supplierbtn_id", res.data.btn_id_json);
              this.$setlocalStorage("suppliertableData", res.data.auth_json);
              this.$setlocalStorage(
                "supplierAuth_id_json",
                res.data.auth_id_json
              );
              this.$setlocalStorage(
                "supplierUserInfo",
                JSON.stringify(userInfo)
              );

              //登陆状态记录
              // window.this.$setlocalStorage("authenticated", true);
              this.$router.push("/app/dashboard");
              //登录成功开启长连接
              this.global.initWebSocket();
              this.global.websocketonmessage(this.onMessage);
              //暂时用刷新来解决菜单栏变化
              this.$message({
                message: this.$t("aboutus.01F5E5@login_success"),
                type: "success"
              });
            } else {
              this.$message({
                title: "Warning",
                message: res.data.msg,
                type: "warning"
              });
            }
          });
      } else {
        this.$message({
          title: "Warning",
          message: this.$t(
            "shop-goods.9A0301@Please_complete_the_account_password"
          ),
          type: "warning"
        });
      }
    }
  },
  created() {}
};
</script>

<style src="./Login.scss" lang="scss" scoped />
